const setUser = payload => {
  const { data } = payload
  const { email = '', name = '', extra = [] } = data

  return {
    name,
    email,
    extra,
    validEmail: true,
  }
}

const setService = payload => {
  const { data } = payload
  const { dataExtensionExternalKey = '', eventDefinitionKey = '' } = data
  let fields

  if (eventDefinitionKey) {
    fields = {
      dataExtensionExternalKey,
      eventDefinitionKey,
    }
  } else {
    fields = {
      dataExtensionExternalKey,
    }
  }

  return {
    marketingCloud: {
      ...fields,
    },
  }
}

const setOrigin = payload => {
  const { data } = payload
  const { page = 'https://hotmart.com', utm, itm } = data

  return {
    page,
    utm,
    itm,
  }
}

const setCountry = payload => {
  const { data } = payload
  const { country_name: name = '', country_code: code = '' } = data

  return {
    name,
    code,
  }
}

const setPolicies = payload => {
  const { data } = payload
  const { policyAgree = false } = data

  return {
    clubAgreeToRegister: policyAgree,
    emailAgreeToReceive: policyAgree,
  }
}

const newLead = payload => {
  const event = {
    user: setUser({ ...payload }),
    services: setService({ ...payload }),
    origin: setOrigin({ ...payload }),
    country: setCountry({ ...payload }),
    ...setPolicies({ ...payload }),
  }

  return event
}

export { newLead }
