import { getConfig } from './config'
import { newLead } from './dto'

import { useAxios } from '../../config/axios'

const useLeadCapture = async payload => {
  const { endpoint = 'datahub', method = 'post' } = payload

  const config = getConfig({ ...payload })
  const { baseURL, endpoints, driver } = config
  let result = {}

  switch (endpoint) {
    case 'datahub':
      result = await useAxios({
        baseURL,
        headers: { ...payload.headers, ...config.headers },
        endpoint: endpoints[endpoint],
        data: newLead({ ...payload }),
        method,
      })
      break

    case 'calculator':
      result = await useAxios({
        baseURL,
        headers: { ...payload.headers, ...config.headers },
        endpoint: endpoints[endpoint],
        data: payload.data,
        method,
      })
      break

    default:
      break
  }

  return result
}

export { useLeadCapture }
