
export default {
  components: {
    hotHeroMembersArea: () => import('~/components/Organisms/HeroMembersArea/HeroMembersArea.vue'),
    hotContentBlocks: () => import('~/components/Organisms/ContentBlocks/ContentBlocks.vue'),
    hotAccordionToTab: () => import('~/components/Organisms/AccordionToTab/AccordionToTab.vue'),
    hotMembersAreaSocialProof: () => import('~/components/Organisms/MembersAreaSocialProof/MembersAreaSocialProof.vue'),
    hotMembersAreaCarousel: () => import('~/components/Organisms/MembersAreaCarousel/MembersAreaCarousel.vue'),
    hotFaq: () => import('~/components/Organisms/Faq/Faq.vue'),
    hotBanner: () => import('~/components/Molecules/Banner/Banner.vue'),
  },
}
