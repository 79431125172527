import { getConfig } from './config'
import { useAxios } from '../../config/axios'

const useIP = async payload => {
  const { endpoint = 'ip', method = 'get' } = payload

  const config = getConfig({ ...payload })
  const { baseURL, endpoints } = config
  let result = {}

  switch (endpoint) {
    case 'ip':
      result = await useAxios({
        baseURL,
        endpoint: endpoints[endpoint],
        method,
      })
      break

    default:
      break
  }

  return result
}

export { useIP }
