
import { useApiService } from '../../../services/api'

export default {
  data() {
    return {
      lead: {
        page: `https://hotmart.com${this.$route.fullPath}`,
      },
    }
  },

  components: {
    hotHeroWithForm: () => import('~/components/Organisms/HeroWithForm/HeroWithForm.vue'),
    hotClubFeatures: () => import('~/components/Organisms/Club/ClubFeatures/ClubFeatures.vue'),
    hotClubMultichannels: () => import('~/components/Organisms/Club/ClubMultichannels/ClubMultichannels.vue'),
    hotClubLeads: () => import('~/components/Organisms/Club/ClubLeads/ClubLeads.vue'),
    hotSocialProof: () => import('~/components/Organisms/SocialProof/SocialProof.vue'),
    hotClubTools: () => import('~/components/Organisms/Club/ClubTools/ClubTools.vue'),
    hotHomeAccordionToTab: () => import('~/components/Organisms/HomeAccordionToTab/HomeAccordionToTab.vue'),
    hotHomeFaq: () => import('~/components/Organisms/HomeFaq/HomeFaq.vue'),
  },

  methods: {
    async submitForm(data) {
      try {
        const result = await useApiService({
          api: 'leadCapture',
          method: 'post',
          data: { ...data, ...this.lead },
        })

        if (result && result.data.status === 200)
          this.$router.push(this.$t('pages.newMembersArea.hero.form.redirection'))
      } catch (error) {}
    },
  },
}
