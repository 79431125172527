import axios from 'axios'

const useAxios = async payload => {
  const { baseURL, endpoint, data, method, headers, params } = payload

  const API = axios.create({
    baseURL,
    headers,
    params,
  })

  const fetchAPI = async () => {
    switch (method) {
      case 'get':
        return await API.get(endpoint)
      case 'post':
        return await API.post(endpoint, data)
      case 'put':
        return await API.put(endpoint, data)
      case 'delete':
        return await API.delete(endpoint)
      default:
        return await API.get(endpoint)
    }
  }

  async function reTryCatch(payload) {
    const { times = 1 } = payload

    try {
      const result = await fetchAPI()

      return result.data
    } catch (error) {
      if (times > 0) {
        return await reTryCatch({ times: times - 1 })
      } else {
        throw error
      }
    }
  }

  const result = await reTryCatch({ times: 2 })

  return result
}

export { useAxios }
