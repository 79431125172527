import { useIP } from './modules/ip'
import { useLeadCapture } from './modules/leadCapture'
import { usePredict } from './modules/predict'

export const useApiService = async payload => {
  const { api } = payload

  switch (api) {
    case 'leadCapture':
      return await useLeadCapture({ ...payload })

    case 'predict':
      return await usePredict({ ...payload })

    case 'ip':
      return await useIP({ ...payload })

    default:
      return await useLeadCapture({ ...payload })
  }
}
