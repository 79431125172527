import { getConfig } from './config'
import { leadInfo } from './dto'

import { useAxios } from '../../config/axios'

const usePredict = async payload => {
  const { endpoint = 'predict', method = 'post' } = payload

  const config = getConfig({ ...payload })
  const { baseURL, endpoints, driver } = config

  const fetchAPI = {
    axios: await useAxios({
      baseURL,
      headers: { ...payload.headers, ...config.headers },
      endpoint: endpoints[endpoint],
      data: leadInfo({ ...payload }),
      method,
    }),
  }

  const result = await fetchAPI[driver]

  return result
}

export { usePredict }
