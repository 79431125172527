const { ENVIROMENT } = process.env
const { ENVIRONMENT } = process.env

export const getConfig = () => ({
  baseURL:
    ENVIROMENT === 'production' || ENVIRONMENT === 'production'
      ? 'https://api-lead-capture.hotmart.com/v1'
      : 'https://api-lead-capture.buildstaging.com/v1',
  headers: {
    'Content-Type': 'application/json',
  },
  endpoints: {
    datahub: `/datahub`,
    calculator: `/datahub/app-comission-calculator`,
  },
  driver: 'axios',
})
