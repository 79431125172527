const leadInfo = payload => {
  const { data } = payload
  const { formato, nicho, dedicacao, num_seguidores } = data

  return {
    formato,
    nicho,
    dedicacao,
    num_seguidores,
  }
}

export { leadInfo }
