const { ENVIROMENT } = process.env

export const getConfig = payload => ({
  baseURL:
    ENVIROMENT === 'production'
      ? 'https://api-commission-calculator.hotmart.com'
      : 'https://api-commission-calculator.hotmart.com',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*.hotmart.com',
    'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
  },
  endpoints: {
    predict: `/predict`,
  },
  driver: 'axios',
})
